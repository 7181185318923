@charset "UTF-8";
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#about .p-msgImg img {
  display: block;
  width: 100%;
  line-height: 0;
}

/*
ユーティリティ系おまとめファイル
*/
.c-topicspath {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 2;
}
@media screen and (max-width: 1200px ) {
  .c-topicspath {
    top: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .c-topicspath {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 568px) {
  .c-topicspath {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.c-topicspath li {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  font-family: 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo;
  margin-right: 5px;
}
.c-topicspath li::after {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  display: inline-block;
  margin-left: 6px;
}
.c-topicspath li:last-child {
  margin-right: 0;
}
.c-topicspath li:last-child::after {
  content: none;
}
.c-topicspath li > a {
  color: #26366C;
  position: relative;
}
.c-topicspath li > a::after {
  content: "";
  display: inline-block;
  width: 0%;
  height: 2px;
  background-color: #26366C;
  position: absolute;
  bottom: -1px;
  left: 0;
  transition: all 0.2s ease;
}
@media (any-hover: hover) {
  .c-topicspath li > a:hover {
    color: #26366C;
  }
  .c-topicspath li > a:hover::after {
    width: 100%;
  }
}

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 280px;
  background-color: #F2F2F2;
}
@media screen and (max-width: 1024px) {
  .l-lowerCaption {
    height: 200px;
  }
}
.l-lowerCaption::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 394px 123px 0 0;
  border-color: #EDCC15 transparent transparent transparent;
  opacity: 0.44;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 1024px) {
  .l-lowerCaption::before {
    border-width: 84px 26px 0 0;
  }
}
.l-lowerCaption::after {
  content: "";
  display: inline-block;
  width: 250px;
  height: calc(100% + 44px);
  background: url("/inc/image/raw/common/deco/lowerCaption/pic_lowerCaption-right.png") no-repeat center right/contain;
  position: absolute;
  top: 0;
  right: 0;
}
.l-lowerCaption__inner {
  position: relative;
  z-index: 2;
  max-width: 1170px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1200px ) {
  .l-lowerCaption__inner {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .l-lowerCaption__inner {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 568px) {
  .l-lowerCaption__inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.l-lowerCaption__title {
  flex-grow: 1;
  color: #26366C;
}
.l-lowerCaption__title-eng {
  display: block;
  font-size: 62px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: -3px;
}
@media screen and (max-width: 1024px) {
  .l-lowerCaption__title-eng {
    font-size: 47px;
  }
}
.l-lowerCaption__title-ja {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #26366C;
}
@media screen and (max-width: 1024px) {
  .l-lowerCaption__title-ja {
    font-size: 16px;
  }
}

@media screen and (max-width: 470px) {
  #new-graduates .l-lowerCaption__title-eng,
  #requirements .l-lowerCaption__title-eng,
  #amusement-new-graduates .l-lowerCaption__title-eng,
  #amusement-mid-career .l-lowerCaption__title-eng {
    font-size: 38px;
  }
}

#privacy .p-analysisTool {
  margin-top: 20px;
  border: 1px solid #000;
  padding: 20px;
}
#privacy .p-analysisTool__heading {
  font-size: 22px;
  margin-bottom: 15px;
}
@media screen and (max-width: 568px) {
  #privacy .p-analysisTool__heading {
    font-size: 18px;
    margin-bottom: 12px;
  }
}
#privacy .p-analysisTool p:last-child {
  margin-bottom: 0;
}

#about .p-msgContainer {
  overflow: hidden;
}
#about .p-msgContainer__inner {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  display: flex;
}
@media screen and (max-width: 768px) {
  #about .p-msgContainer__inner {
    flex-direction: column;
  }
}
#about .p-msgTitle {
  font-size: 34px;
  font-weight: 600;
  color: #26366C;
  margin-bottom: 35px;
}
@media screen and (max-width: 1024px) {
  #about .p-msgTitle {
    font-size: 28px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  #about .p-msgTitle {
    font-size: 27px;
  }
}
#about .p-msgDesc {
  width: 595px;
  flex-shrink: 0;
  padding-right: 55px;
}
@media screen and (max-width: 1280px) {
  #about .p-msgDesc {
    padding: 0 30px;
    width: 540px;
  }
}
@media screen and (max-width: 1024px) {
  #about .p-msgDesc {
    width: 420px;
  }
}
@media screen and (max-width: 768px) {
  #about .p-msgDesc {
    width: 100%;
    margin-bottom: 25px;
    padding: 0 20px;
  }
}
@media screen and (max-width: 568px) {
  #about .p-msgDesc {
    padding: 0 15px;
  }
}
#about .p-msgImg {
  width: 735.2px;
  flex-shrink: 0;
  position: relative;
}
@media screen and (max-width: 1024px) {
  #about .p-msgImg {
    width: 650px;
  }
}
@media screen and (max-width: 768px) {
  #about .p-msgImg {
    width: calc(100% + 83px);
    position: relative;
    padding-left: 20px;
  }
}
@media screen and (max-width: 568px) {
  #about .p-msgImg {
    padding-left: 15px;
  }
}
#about .p-map {
  max-width: 800px;
  margin: auto;
  aspect-ratio: 16/9;
}
#about .p-map iframe {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1024px) {
  #about .p-philosophy .c-boxDeco__inner {
    padding: 50px 60px 90px;
  }
}
@media screen and (max-width: 768px) {
  #about .p-philosophy .c-boxDeco__inner {
    padding: 40px 30px 60px;
  }
}
@media screen and (max-width: 568px) {
  #about .p-philosophy .c-boxDeco__inner {
    padding: 30px 20px 60px;
  }
}
#about .p-philosophy .p-words__title {
  margin-bottom: 30px;
  color: #26366C;
  font-size: 35px;
  font-family: 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo;
  text-align: center;
}
@media screen and (max-width: 568px) {
  #about .p-philosophy .p-words__title {
    font-size: 26px;
  }
}
@media screen and (max-width: 400px) {
  #about .p-philosophy .p-words__title {
    font-size: 24px;
  }
}
#about .p-philosophy .p-words__list {
  display: flex;
  justify-content: center;
  margin-right: -18px;
  margin-left: -18px;
  margin-bottom: 70px;
}
@media screen and (max-width: 834px) {
  #about .p-philosophy .p-words__list {
    display: block;
    width: 100%;
    max-width: 330px;
    margin: 0 auto 55px;
  }
}
#about .p-philosophy .p-words__item {
  padding: 0 18px;
  text-align: center;
}
@media screen and (max-width: 1024px) {
  #about .p-philosophy .p-words__item {
    padding: 0 15px;
  }
}
@media screen and (max-width: 834px) {
  #about .p-philosophy .p-words__item {
    text-align: left;
    margin-bottom: 20px;
  }
  #about .p-philosophy .p-words__item:last-child {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 568px) {
  #about .p-philosophy .p-words__item {
    padding: 0;
  }
}
#about .p-philosophy .p-words__item > h4 {
  line-height: 1.15;
}
#about .p-philosophy .p-words__item--en {
  display: inline-block;
  font-weight: 700;
  color: #26366C;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  margin-bottom: 4px;
}
@media screen and (max-width: 834px) {
  #about .p-philosophy .p-words__item--en {
    width: 95px;
  }
}
@media screen and (max-width: 568px) {
  #about .p-philosophy .p-words__item--en {
    font-size: 12px;
  }
}
#about .p-philosophy .p-words__item--en::first-letter {
  font-size: 200%;
}
#about .p-philosophy .p-words__item--ja {
  display: block;
  font-size: 23px;
  font-family: 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 1024px) {
  #about .p-philosophy .p-words__item--ja {
    font-size: 21px;
  }
}
@media screen and (max-width: 834px) {
  #about .p-philosophy .p-words__item--ja {
    display: inline;
  }
}
@media screen and (max-width: 568px) {
  #about .p-philosophy .p-words__item--ja {
    font-size: 21px;
  }
}
#about .p-philosophy .p-words__item:nth-child(1) .p-words__item--en::first-letter {
  margin-right: -2px;
}
#about .p-philosophy .p-detail {
  width: 100%;
  max-width: 548px;
  margin: 0 auto;
}
#about .p-philosophy .p-detail__title {
  color: #26366C;
  font-size: 30px;
  font-family: 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo;
  text-align: center;
}
@media screen and (max-width: 568px) {
  #about .p-philosophy .p-detail__title {
    font-size: 20px;
  }
}
@media screen and (max-width: 400px) {
  #about .p-philosophy .p-detail__title {
    font-size: 19px;
  }
}
#about .p-philosophy .p-detail__text {
  font-size: 20px;
}
@media screen and (max-width: 834px) {
  #about .p-philosophy .p-detail__text {
    font-size: 18px;
  }
}
@media screen and (max-width: 568px) {
  #about .p-philosophy .p-detail__text {
    font-size: 16px;
  }
}